export const enum Roles {
  "creator.admin" = "creator.admin",
  "md.admin" = "md.admin",
}

export const enum RolesToLabel {
  "creator.admin" = "Creator",
  "md.admin" = "Partner",
}

export const enum ContentPermissions {
  INVITE = "INVITE",
  DESIGN_FILM_PAGE = "DESIGN_FILM_PAGE",
  VIEW_FILM_PAGE = "VIEW_FILM_PAGE",
  UPLOAD_PROTECTED_FILES = "UPLOAD_PROTECTED_FILES",
  MANAGE_FILM_PAGE = "MANAGE_FILM_PAGE",
  MANAGE_KYC_KYB = "MANAGE_KYC_KYB",
  MANAGE_BANKING_DETAILS = "MANAGE_BANKING_DETAILS",
  MANAGE_CONTENT_SETTINGS = "MANAGE_CONTENT_SETTINGS",
  INVITE_NON_PROFIT = "INVITE_NON_PROFIT",
  MANAGE_CONTENT_MEDIA_KITS = "MANAGE_CONTENT_MEDIA_KITS",
  PUBLISH_CONTENT = "PUBLISH_CONTENT",
  INVITE_PARTNERS = "INVITE_PARTNERS",
  INVITE_ADVOCATES = "INVITE_ADVOCATES",
  MANGE_PAYOUTS = "MANGE_PAYOUTS",
  READ_ALL_ANALYTICS = "READ_ANALYTICS",
  READ_ALL_TRANSACTIONS = "READ_TRANSACTIONS",
  READ_PARTNER_ANALYTICS = "READ_PARTNER_ANALYTICS",
  READ_USERS = "READ_USERS",
  DOWNLOAD_USERS = "DOWNLOAD_USERS",
  MANAGE_3RD_PARTY_PLUGINS = "MANAGE_3RD_PARTY_PLUGINS",
  READ_EMAIL_NOTIFICATIONS = "READ_EMAIL_NOTIFICATIONS",
}

export enum ContentRoles {
  DEVELOPER = 0,
  VIEWER = 1,
  EDITOR = 2,
  MANAGER = 3,
  ADMIN = 4,
}

// Function to convert a string to the corresponding enum value
export const getRoleValue = (role?: string): ContentRoles | undefined => {
  if (role) {
    return ContentRoles[role as keyof typeof ContentRoles];
  }
  return;
};
